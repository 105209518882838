import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { CssEase } from "../../animation/ease";
import { useIsVisible } from "../../hooks/useVisbile";

const Div = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  max-width: 100vw;
  text-align: center;
  z-index: 10;
  p {
    cursor: default;
  }
  @media (max-width: 768px) {
    height: 100vh;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 0px 0px 20px 0px;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: calc(100vw - 10px);
    margin-left: auto;
    margin-right: auto;
  }
`


const enterFrame = keyframes`
  from {
    transform: translateY(90px) rotate(10deg);
    opacity: 0;
  }
  to {
    transform: translateY(0px) rotate(0deg);
    opacity: 1;
  }
`;

const exitFrame = keyframes`
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-90px);
  }
`;

const Line = styled.div`
  overflow: hidden;
  padding-bottom: 6px;
  @media (max-width: 768px) {
    max-width: 100%;
    display: inline-block;
    overflow: visible;
    padding-left: 20px;
    padding-right: 20px;
    br {
      display: none;
    }
  }
  &.out {
    h1,h2, p {
      animation: ${exitFrame} 0.6s normal ${CssEase.inCirc} forwards;
    }
  }
  h1 {
    transform: translateY(90px) rotate(10deg);
    animation: ${enterFrame} 0.6s normal ${CssEase.outCirc} forwards;
    animation-delay: 0s;
    will-change: transform;
    white-space: nowrap;
    opacity: 0;
  }
  h2, p {
    transform: translateY(90px) rotate(10deg);
    animation: ${enterFrame} 0.6s normal ${CssEase.outCirc} forwards;
    animation-delay: 0.3s;
    will-change: transform;
    white-space: nowrap;
    opacity: 0;
    @media (max-width: 768px) {
      white-space: normal;
    }
  }
`

const Down = (props) => {

  const arrowRef = useRef();
  const visible = useIsVisible({ element: arrowRef });
  let lineClass = visible ? "in" : "out";

  return (
    <Div ref={arrowRef}>
      <Line className={lineClass}><p>↓</p></Line>
    </Div>
  )


}

export default Down;
