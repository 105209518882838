import React from 'react';
import styled from 'styled-components'

import Angellist from '../../images/angellist.svg';
import Github from '../../images/github.svg';
import Linkedin from '../../images/linkedin.svg';
import Npm from '../../images/npm.svg';
import Soundcloud from '../../images/soundcloud.svg';
import Spotify from '../../images/spotify.svg';
import Twitch from '../../images/twitch.svg';
import Twitter from '../../images/twitter.svg';
import Telegram from '../../images/telegram.svg';
import Tumblr from '../../images/tumblr.svg'
import Polywork from '../../images/polywork.svg';
import LiteralClub from '../../images/literalclub.svg'

const Container = styled.div`
  min-height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
`

const Div = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  a {
    margin-left: 10px;
    margin-right: 10px;
    @media (max-width: 768px) {
      margin-left: 5px;
      margin-right: 5px;
    }
    img {
      transition: all 0.25s ease-in-out;
      height: 24px;
      &:hover {
        opacity: 0.6;
      }
    }
  }
`

const Social = (props) => {
  return(
    <Container>
    <h2>Let's be internet BFFs</h2>
    <Div>
      <a aria-label="Twitter" href="https://twitter.com/pierre_nel" rel="noopener noreferrer"><img alt="Twitter" src={Twitter} /></a>
      <a aria-label="Telegram" href="https://t.me/pierre_nel" rel="noopener noreferrer"><img alt="Telegram" src={Telegram} /></a>
      <a aria-label="Polywork" href="https://www.polywork.com/nel" rel="noopener noreferrer"><img alt="Polywork" src={Polywork} /></a>
      <a aria-label="LiteralClub" href="https://literal.club/pn" rel="noopener noreferrer"><img alt="LiteralClub" src={LiteralClub} /></a>
      <a aria-label="Spotify" href="https://open.spotify.com/user/pierrenel?si=liNtqLffQQmmlZxOmYGxNA" rel="noopener noreferrer"><img alt="Spotify" src={Spotify} /></a>
      <a aria-label="Tumblr" href="https://blog.pierre.io" rel="noopener noreferrer"><img alt="Tumblr" src={Tumblr} /></a>
      <a aria-label="LinkedIn" href="http://uk.linkedin.com/in/pierrenel" rel="noopener noreferrer"><img alt="LinkedIn" src={Linkedin} /></a>
      <a aria-label="Github" href="https://github.com/pierrenel/" rel="noopener noreferrer"><img alt="Github" src={Github} /></a>
      <a aria-label="Soundcloud" href="https://soundcloud.com/pierrenel" rel="noopener noreferrer"><img alt="Soundcloud" src={Soundcloud} /></a>
      <a aria-label="Wellfound" href="https://angel.co/pierre_nel" rel="noopener noreferrer"><img alt="Angellist" src={Angellist} /></a>
      <a aria-label="Twitch" href="https://www.twitch.tv/pierre_dot_io" rel="noopener noreferrer"><img alt="Twitch" src={Twitch} /></a>
    </Div>
    </Container>
  )
}

export default Social;
