import React, { useRef } from "react"
import styled, { keyframes } from "styled-components"
import { CssEase } from "../../animation/ease"
import { useIsVisible } from "../../hooks/useVisbile"

const enterFrame = keyframes`
  from {
    transform: translateY(90px) rotate(10deg);
    opacity: 0;
  }
  to {
    transform: translateY(0px) rotate(0deg);
    opacity: 1;
  }
`

const exitFrame = keyframes`
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-90px);
  }
`

const Line = styled.div`
  overflow: hidden;
  padding-bottom: 6px;
  &:nth-of-type(1) h3 {
    animation-delay: 0.3s;
  }
  &:nth-of-type(1) h3 {
    animation-delay: 0.4s;
  }
  &:nth-of-type(2) h3 {
    animation-delay: 0.5s;
  }
  &:nth-of-type(3) h3 {
    animation-delay: 0.6s;
  }
  &:nth-of-type(4) h3 {
    animation-delay: 0.7s;
  }
  &:nth-of-type(5) h3 {
    animation-delay: 0.8s;
  }
  &:nth-of-type(6) h3 {
    animation-delay: 0.9s;
  }
  &:nth-of-type(7) h3 {
    animation-delay: 1s;
  }
  &:nth-of-type(8) h3 {
    animation-delay: 1.1s;
  }
  &:nth-of-type(9) h3 {
    animation-delay: 1.2s;
  }
  &:nth-of-type(10) h3 {
    animation-delay: 1.3s;
  }
  &:nth-of-type(11) h3 {
    animation-delay: 1.4s;
  }
  &:nth-of-type(12) h3 {
    animation-delay: 1.5s;
  }
  &:nth-of-type(13) h3 {
    animation-delay: 1.6s;
  }
  &:nth-of-type(14) h3 {
    animation-delay: 1.7s;
  }
  &:nth-of-type(15) h3 {
    animation-delay: 1.8s;
  }
  &.out {
    h2,
    h3 {
      animation: ${exitFrame} 0.6s normal ${CssEase.inCirc} forwards;
      @media (max-width: 768px) {
        animation: none;
        opacity: 1;
      }
    }
  }
  h2 {
    transform: translateY(90px) rotate(10deg);
    animation: ${enterFrame} 0.6s normal ${CssEase.outCirc} forwards;
    animation-delay: 0s;
    will-change: transform;
    line-height: 1;
    white-space: nowrap;
    margin-bottom: 12px;
    opacity: 0;
    @media (max-width: 768px) {
      animation: none;
      opacity: 1;
      transform: none;
    }
  }
  h3 {
    transform: translateY(90px) rotate(10deg);
    animation: ${enterFrame} 0.6s normal ${CssEase.outCirc} forwards;
    will-change: transform;
    line-height: 1;
    white-space: nowrap;
    color: #777;
    opacity: 0;
    @media (max-width: 768px) {
      animation: none;
      opacity: 1;
      transform: none;
    }
  }
`

const Section = styled.section`
  background-color: ${props => props.theme.darkgrey};
  padding: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 70vh;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 20px;
    justify-content: flex-start;
    text-align: left;
  }
  h2 {
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 768px) {
      text-align: left;
      display: block;
      width: 100%;
    }
  }
  p {
    margin-top: 40px;
    max-width: 700px;
    text-align: center;
  }
  .wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: -20px;
    @media (max-width: 768px) {
      margin-left: 0px;
      align-items: flex-start;
      width: 100%;
    }
  }
  .wrapper:hover h3 a:not(:hover) {
    opacity: 0.5;
  }
  h3 {
    margin-bottom: 6px;
    &:hover {
      a {
        color: #c7c5c1;
        opacity: 1;
      }
      sup {
        color: #c7c5c1;
      }
    }
    sup {
      font-size: 14px;
      line-height: 24px;
      margin-right: 6px;
      transition: all 0.25s ease-in-out;
    }
    a {
      color: #999;
      text-decoration: none;
      transition: all 0.25s ease-in-out;
      &:after {
        content: "";
        display: block;
        width: 0;
        margin-top: 4px;
        height: 1px;
        background: #999;
        transition: width 0.3s;
      }
      &:hover {
        color: #c7c5c1;
        &:after {
          width: 100%;
        }
      }
    }
  }
`

const PreviousWork = styled.section`
  padding: 50px 110px 50px 110px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  min-height: 70vh;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 20px;
  }
  h2,
  p {
    text-align: center;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    @media (max-width: 768px) {
      justify-content: flex-start;
      width: 99%;
    }
    /* &:after {
      content: "";
      flex: auto;
    } */
  }
  li {
    display: inline-block;
    margin: 0px;
    font-family: "hl";
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: #777;
    padding: 0px 20px 0px 20px;
    white-space: nowrap;
    margin-bottom: 6px;
    @media (max-width: 768px) {
      padding-left: 0px;
      width: 100%;
      padding-right: 0px;
    }
    span {
      /* white-space: nowrap; */
    }
  }
`

const recent_projects = [
  {
    name: "PBJ Mangement",
    url: "https://www.pbjmanagement.co.uk/",
    tech: "Gatsby + Sanity",
  },
  {
    name: "Acierta Retail",
    url: "https://aciertaretail.com/",
    tech: "Gatsby + Sanity",
  },
  {
    name: "Bespoke Voice Agency",
    url: "https://bespokevoiceagency.co.uk/",
    tech: "Drupal",
  },
  {
    name: "Binske",
    url: "https://binske.com/",
    tech: "Gatsby",
  },
  {
    name: "Clayton & Co",
    url: "https://clayton.co.uk/",
    tech: "Gatsby + Prismic",
  },
  {
    name: "Future Girl Corp",
    url: "http://worldwide.futuregirlcorp.com/",
    tech: "Drupal",
  },
  {
    name: "Ghost Fragrances",
    url: "https://ghostfragrances.com/",
    tech: "Drupal",
  },
  {
    name: "Hot Pottery",
    url: "https://hotpottery.co.uk/",
    tech: "Shopify",
  },
  {
    name: "Intrepid London",
    url: "http://www.intrepidlondon.com/",
    tech: "Drupal",
  },
  {
    name: "Man From Uncle",
    url: "https://manfromuncle.info/",
    tech: "Wordpress",
  },
  {
    name: "Custom Mouse Pads",
    url: "https://omegaloot.co.uk/",
    tech: "Shopify",
  },
  {
    name: "Scarborough, Cape Town",
    url: "https://scarborough.capetown",
    tech: "Gatsby",
  },
  {
    name: "Something & Nothing",
    url: "https://somethingandnothing.co/",
    tech: "Shopify",
  },
  {
    name: "Theodora Warre Jewellery",
    url: "https://theodorawarre.eu/",
    tech: "Shopify + Gatsby + Prismic + MatterJS",
  },
  {
    name: "Troika Talent Agency",
    url: "https://www.troikatalent.com/",
    tech: "Drupal",
  },
]

const all_projects = [
  "Annie Lennox",
  "Barbour",
  "BBC",
  "The Brit Trust",
  "Carbon Trust",
  "Cartoon Network",
  "Deloitte",
  "de Pury de Pury",
  "Diamond Management",
  "Eager drinks",
  "Frahm & Frahm",
  "Global Action Plan",
  "Goldfrapp",
  "Hiveworks",
  "Intel",
  "Iranwire",
  "Journalism is not a Crime",
  "London.gov.uk",
  "Lotus Cars",
  "Monocle magazine",
  "Orange",
  "Panasonic",
  "Peroni Italy",
  "Playboy Fragrances",
  "Pilsner Urquell",
  "Placebo",
  "Reebok",
  "Sheerluxe",
  "Skin",
  "Skunk Anansie",
  "Tailor Made Voices",
  "TNS Global",
]

const Projects = props => {
  const projectRef = useRef()
  const visible = useIsVisible({ element: projectRef })
  let lineClass = visible ? "in" : "out"

  // <p>Technical bits:<br />These sites were created using GatsbyJS, React, styled-components, Storybook, Prismic, Drupal, Wordpress, Three.js, Matter.js and the Shopify API.</p>

  return (
    <>
      <Section>
        <h2>Recent work</h2>
        <div className="wrapper" ref={projectRef}>
          {recent_projects.map((p, index) => {
            const num = index < 11 ? "0" + (index + 1) : index + 1
            return (
              <Line key={index} className={lineClass}>
                <h3>
                  <sup>{num}</sup>
                  <a href={p.url} target="_blank" rel="noopener noreferrer">
                    {p.name}
                  </a>
                </h3>
              </Line>
            )
          })}
        </div>
      </Section>
      <PreviousWork>
        <h2>Previous work</h2>
        <ul>
          {all_projects.map((p, index) => {
            return (
              <li key={index}>
                <span>{p}</span>
              </li>
            )
          })}
        </ul>
      </PreviousWork>
    </>
  )
}

export default Projects
