import * as THREE from 'three'
import ReactDOM from 'react-dom'
import styled from 'styled-components';
import React, { useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas, extend, useFrame, useRender, useThree } from 'react-three-fiber'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { WaterPass } from './Waterpass'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Down from '../downarrow';

// Makes these prototypes available as "native" jsx-string elements
extend({ EffectComposer, ShaderPass, RenderPass, WaterPass, AfterimagePass, UnrealBloomPass, OrbitControls })

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  color: ${props => props.theme.text_color};
  background-color: ${props => props.theme.darkgrey};
  position: relative;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 100vw;
  }
  canvas {
    outline: 0;
    &:focus, &:active {
      outline: 0;
    }
  }
  &:focus, &:active {
    outline: 0;
  }
`

const Controls = props => {
  const { gl, camera } = useThree()
  const ref = useRef()
  useRender(() => ref.current.update())
  return <orbitControls ref={ref} args={[camera, gl.domElement]} {...props} />
}

function Swarm({ count, mouse }) {
  const mesh = useRef()
  const light = useRef()
  const { size, viewport } = useThree()
  const aspect = size.width / viewport.width

  const dummy = useMemo(() => new THREE.Object3D(), [])
  // Generate some random positions, speed factors and timings
  const particles = useMemo(() => {
    const temp = []
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100
      const factor = 20 + Math.random() * 2000
      const speed = 0.001 + Math.random() / 2000
      const xFactor = -1000 + Math.random() * 2000;
      const yFactor = -1000 + Math.random() * 2000;
      const zFactor = -600 + Math.random() * 1200;
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
    }
    return temp
  }, [count])
  // The innards of this hook will run every frame
  useFrame(state => {
    // Makes the light follow the mouse
    light.current.position.set(mouse.current[0] / aspect, -mouse.current[1] / aspect, 20);
    // Run through the randomized data to calculate some movement
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle
      // There is no sense or reason to any of this, just messing around with trigonometric functions
      t = particle.t += speed / 10
      const a = Math.cos(t) + Math.sin(t * 1) / 10
      const b = Math.sin(t) + Math.cos(t * 2) / 10
      const s = Math.cos(t) * 1
      particle.mx += (mouse.current[0] - particle.mx) * 0.01
      particle.my += (mouse.current[1] * -1 - particle.my) * 0.01
      // Update the dummy object
      dummy.position.set(
        (particle.mx / 10) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
        (particle.my / 10) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
      )
      dummy.scale.set(s, s, s)
      dummy.rotation.set(s * 5, s * 5, s * 5)
      dummy.updateMatrix()
      // And apply the matrix to the instanced item
      mesh.current.setMatrixAt(i, dummy.matrix)
    })
    mesh.current.instanceMatrix.needsUpdate = true
  })
  return (
    <>
    <pointLight ref={light} distance={200} intensity={4} color="#777777">
      <mesh>
        <sphereBufferGeometry attach="geometry" args={[3, 3, 3]} />
        <meshBasicMaterial attach="material" color="#777777" />
      </mesh>
    </pointLight>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <dodecahedronBufferGeometry attach="geometry" args={[0.8, 0]} />
        <meshStandardMaterial attach="material" color="#ffffff" />
      </instancedMesh>
    </>
  )
}

function Effect() {
  const composer = useRef()
  const { scene, gl, size, camera } = useThree()
  const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
  useEffect(() => void composer.current.setSize(size.width, size.height), [size])
  useFrame(() => composer.current.render(), 1)
  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" scene={scene} camera={camera} />
      <waterPass attachArray="passes" factor={1} />
      <afterimagePass attachArray="passes" uniforms-damp-value={0.3} />

      <shaderPass attachArray="passes" args={[FXAAShader]} uniforms-resolution-value={[1 / size.width, 1 / size.height]} renderToScreen />
    </effectComposer>
  )
}

const Donut = function() {
  const ref = useRef()
  useFrame(() => (ref.current.rotation.z = ref.current.rotation.z += 0.003))
  return (
    <mesh
      position={[120,120,0]}
      ref={ref}
      >
        <torusGeometry attach="geometry" args={[ 80, 40, 32, 100 ]} />
        <meshPhongMaterial attach="material" color="#444444" specular="#e8e3dd" shininess="15" opacity="0.9" flatShading />
      </mesh>
  )
}

const I = function() {
  const ref = useRef()
  useFrame(() => (ref.current.rotation.y = ref.current.rotation.y -= 0.003))
  return (
    <mesh
      position={[-100, 0, 0]}
      ref={ref}
      >
        <boxGeometry attach="geometry" args={[ 110, 540, 120 ]} />
        <meshPhongMaterial attach="material" color="#444444" specular="#e8e3dd" shininess="15" opacity="0.9" flatShading />
      </mesh>
  )
}

function Dolly() {
  // This one makes the camera move in and out
  useFrame(({ clock, camera }) => camera.updateProjectionMatrix(void (camera.position.z = 600 + Math.sin(clock.getElapsedTime()) * 10)))
  return null
}

const Intro = (props) => {
  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
  return (
    <Container>
      <Down />
      <div style={{ width: '100vw', height: '100vh' }} onMouseMove={onMouseMove}>
        <Canvas camera={{ fov: 75, position: [0, 0, 600] }}>
        <ambientLight color="#888888" intensity={0.7} />
        <fog attach="fog" args={['#171717', 30, 10]} />
          <fog attach="fog" args={['#171717', 30, 10]} />
          <pointLight intensity={0.2} position={[0, 0, 50]} angle={0.8} penumbra={1} castShadow />
          <I />
          <Donut />
          <Swarm mouse={mouse} count={20000} />
          <Effect />
          <Controls
            enablePan={false}
            enableZoom={false}
            enableRotate={true}
            enableDamping
            dampingFactor={0.5}
            rotateSpeed={0.1}
            maxPolarAngle={Math.PI / 2}
            minPolarAngle={Math.PI / 2}
          />
        </Canvas>
      </div>
    </Container>
  )
}

export default Intro;
