import React from 'react';
import Layout from '../layouts';
import SEO from '../components/seo';
import Contact from '../components/contact';
import Intro from '../components/intro';
import Projects from '../components/projects';
import Social from '../components/social';

const IndexPage = () => {

  // const scrollRef = React.createRef();

  // useEffect(() => {
  //   const scroll = new locomotiveScroll({
  //     el: scrollRef.current,
  //     smooth: true
  //   });
  // });

  return(
    <Layout>
      <SEO title="Portfolio – Pierre Nel" />
      <Intro />
      <Contact />
      <Projects />
      <Social />
    </Layout>
  )
}

export default IndexPage
